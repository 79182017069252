var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-card-body",
        [
          !_vm.usingNewForm && _vm.loading
            ? _c(
                "div",
                { staticClass: "text-center loading-div" },
                [_c("ct-centered-spinner")],
                1
              )
            : _vm._e(),
          _vm.isServices
            ? _c(
                "span",
                [
                  _c("fa-icon", {
                    staticClass: "question-circle pl-1 help-text",
                    attrs: { icon: "question-circle", size: "1x" },
                  }),
                  _c(
                    "a",
                    {
                      staticClass: "edit-link",
                      attrs: { "aria-label": "why link" },
                      on: {
                        click: _vm.showItemRequiringAttentionInformationModal,
                      },
                    },
                    [
                      _vm._v(
                        "\n        Why Do I need to Provide This Information?\n      "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("item-requiring-attention-information-modal", {
            ref: "item-requiring-attention-information-modal",
          }),
          !_vm.usingNewForm ? _c("hr") : _vm._e(),
          _vm.usingNewForm
            ? _c("data-map-form", {
                attrs: {
                  "form-mode": "URL",
                  "source-item": _vm.item,
                  "parent-loading": _vm.loading,
                },
                on: {
                  loading: function ($event) {
                    _vm.loading = $event
                  },
                  "save-details": _vm.saveDetails,
                },
              })
            : _c(
                "div",
                { attrs: { id: "tab-handler-container", tabindex: 1 } },
                [
                  !_vm.loading
                    ? _c("schema-form", {
                        ref: `filing-form-${_vm.item.id}`,
                        attrs: {
                          fields: _vm.form,
                          "contextual-jurisdiction": _vm.contextualJurisdiction,
                          "suggestion-fields": _vm.suggestionFields,
                        },
                        on: {
                          "ra-signup": _vm.raSignUp,
                          "show-contact-modal": _vm.showContactModal,
                          "suggestion-toggled": function ($event) {
                            return _vm.suggestionToggled($event)
                          },
                        },
                        model: {
                          value: _vm.formValues,
                          callback: function ($$v) {
                            _vm.formValues = $$v
                          },
                          expression: "formValues",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
          _c(
            "div",
            { staticClass: "text-center d-flex justify-content-center" },
            [
              _c(
                "b-button",
                {
                  staticClass: "submit-btn-width mx-2",
                  attrs: {
                    id: "submit-btn",
                    variant: "primary",
                    "aria-label": "confirm button",
                    disabled: _vm.loading || _vm.disableSubmitButton,
                  },
                  on: { click: _vm.saveDetails },
                },
                [_vm._v("\n        Confirm\n      ")]
              ),
              _vm.canSaveProgress
                ? _c(
                    "b-button",
                    {
                      staticClass: "btn-secondary mx-2",
                      attrs: {
                        variant: "secondary",
                        "aria-label": "save progress button",
                        disabled: _vm.loading || _vm.disableSubmitButton,
                      },
                      on: { click: _vm.saveFormProgress },
                    },
                    [_vm._v("\n        Save Progress\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("contact-modal", {
        ref: "contact-modal",
        attrs: { title: "Add a contact", type: "add" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }