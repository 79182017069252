<template>
  <b-container>
    <b-card-body>
      <!-- \/ safe to remove when fully on new form -->
      <div v-if="!usingNewForm && loading" class="text-center loading-div">
        <ct-centered-spinner />
      </div>
      <!-- /\ safe to remove when fully on new form -->

      <span v-if="isServices">
        <fa-icon
          class="question-circle pl-1 help-text"
          icon="question-circle"
          size="1x"
        />
        <a
          class="edit-link"
          aria-label="why link"
          @click="showItemRequiringAttentionInformationModal"
        >
          Why Do I need to Provide This Information?
        </a>
      </span>

      <item-requiring-attention-information-modal
        ref="item-requiring-attention-information-modal"
      />

      <hr v-if="!usingNewForm"> <!-- < safe to remove when fully on new form -->

      <data-map-form
        v-if="usingNewForm"
        form-mode="URL"
        :source-item="item"
        :parent-loading="loading"
        @loading="loading = $event"
        @save-details="saveDetails"
      />

      <!-- \/ safe to remove when fully on new form -->
      <div
        v-else
        id="tab-handler-container"
        :tabindex="1"
      >
        <schema-form
          v-if="!loading"
          :ref="`filing-form-${ item.id }`"
          v-model="formValues"
          :fields="form"
          :contextual-jurisdiction="contextualJurisdiction"
          :suggestion-fields="suggestionFields"
          @ra-signup="raSignUp"
          @show-contact-modal="showContactModal"
          @suggestion-toggled="suggestionToggled($event)"
        />
      </div>
      <!-- /\ safe to remove when fully on new form -->

      <!-- \/ not currently in use but was told to hold for the future -->
      <!--      <div v-if="isCreditCardOrder && !loading">-->
      <!--        <credit-card-processing-custom-ora-form-->
      <!--          @updateCustomData="customOraFormValues = $event"-->
      <!--        />-->
      <!--      </div>-->
      <!-- /\ not currently in use but was told to hold for the future -->

      <div class="text-center d-flex justify-content-center">
        <b-button
          id="submit-btn"
          class="submit-btn-width mx-2"
          variant="primary"
          aria-label="confirm button"
          :disabled="loading || disableSubmitButton"
          @click="saveDetails"
        >
          Confirm
        </b-button>
        <b-button
          class="btn-secondary mx-2"
          variant="secondary"
          aria-label="save progress button"
          v-if="canSaveProgress"
          :disabled="loading || disableSubmitButton"
          @click="saveFormProgress"
        >
          Save Progress
        </b-button>
      </div>
    </b-card-body>

    <!-- \/ safe to remove when fully on new form -->
    <contact-modal ref="contact-modal" :title="'Add a contact'" :type="'add'" />
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { fillFormFromDetails, saveDetails, createDocumentFromForm } from '@/components/SchemaForm/helper.js'
import _ from 'lodash'

export default {
  name: 'ItemRequiringAttention',
  components: {
    // CreditCardProcessingCustomOraForm:   () => import('@/components/CreditCardProcessingCustomOraForm'),
    SchemaForm:                             () => import('@/components/SchemaForm'),
    DataMapForm:                            () => import('@core/data-map-frontend/src/components/Form/DataMapForm'),
    ContactModal:                           () => import('@/components/ContactModal'),
    CtCenteredSpinner:                      () => import('@/components/shared/CtCenteredSpinner'),
    ItemRequiringAttentionInformationModal: () => import('@/components/ItemRequiringAttentionInformationModal'),
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    services: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contextualJurisdiction: null, // < safe to remove when fully on new form
      form: {}, // < safe to remove when fully on new form
      formValues: {}, // < safe to remove when fully on new form
      loading: false,
      suggestionFields: [], // < safe to remove when fully on new form
      CREATE_DOCUMENT_FORMS: [ // names of forms which should be saved to client documents
        'Standard Federal Cease and Desist filing',
        'USPTO Filing Fee per Federal US Patent and Trademark filing',
      ],
    }
  },
  computed: {
    ...mapGetters('jurisdictions', ['findByName']), // < safe to remove when fully on new form
    ...mapGetters('schema', ['schema']), // < safe to remove when fully on new form
    ...mapGetters('companies', ['domesticRegistration']),
    ...mapGetters('dataMap', ['disableSubmitButton']),
    // isCreditCardOrder(){ <!-- < not currently in use but was told to hold for the future -->
    //   return this.item.type === 'merchant-service'
    // },
    isServices() {
      return !!this.services
    },
    isWyomingRA() { // < safe to remove when fully on new form
      return this.item?.jurisdiction?.abbreviation === 'WY' &&
        this.item?.type === 'registered-agent'
    },
    isEIN() {
      return this.item?.jurisdiction === 'Federal' &&
        this.item?.product?.filing_name === 'tax id'
    },
    usingNewForm() { // < safe to remove when fully on new form
      return this.item?.client_schema_version > 1
    },
    isMontanaVehiclesRegistration() {
      return this.item?.jurisdiction === 'Montana' &&
        this.item?.product?.filing_name === 'vehicle registration'
    },
    prefillFormValuesWithCompanyDetails() {
      return !this.isServices && !this.isEIN && !this.isWyomingRA
    },
    orderItemData() {
      return this.item?.data?.default ? this.item.data.default : this.item.data
    },
    canSaveProgress() {
      return this.usingNewForm && !this.isServices && !this.isEIN && !this.isWyomingRA
    },
  },
  async mounted() {
    this.loading = true // < safe to remove when fully on new form
    await this.setCurrentCompany({ id: this.item.company.id })

    // \/ safe to remove when fully on new form
    if (!this.usingNewForm) {
      this.setJurisdiction()

      await this.setForm()
      this.setShowAddressPrivacyBorder(true)
      this.loading = false
    }
    // /\ safe to remove when fully on new form
  },
  created() { // < safe to remove when fully on new form
    if (!this.usingNewForm) {
      this.$nextTick(() => {
        document.getElementById('tab-handler-container')
          .addEventListener('keypress', this.handleEnterTabbing)
      })
    }
  },
  beforeDestroy() { // < safe to remove when fully on new form
    if (!this.usingNewForm) {
      if (document.getElementById('tab-handler-container'))
        document.getElementById('tab-handler-container')
          .removeEventListener('keypress', this.handleEnterTabbing)

      this.setShowAddressPrivacyBorder(true)
    }
  },
  methods: {
    ...mapActions('companies', ['setCurrentCompany']),
    ...mapActions('checkout', [ // < safe to remove when fully on new form
      'addToCart', // < safe to remove when fully on new form
      'fetchProduct',  // < safe to remove when fully on new form
    ]),
    ...mapActions('schema', [ // < safe to remove when fully on new form
      'loadSchema', // < safe to remove when fully on new form
      'setObject', // < safe to remove when fully on new form
      'setShowAddressPrivacyBorder', // < safe to remove when fully on new form
    ]),
    ...mapActions('dataMap', [
      'validateCurrentForm',
      'setShowValidationErrors',
      'submitData',
    ]),
    handleEnterTabbing(e) { // < safe to remove when fully on new form
      if (e.key !== 'Enter') return
      const fieldList = Array.from(document.getElementById('tab-handler-container')
        ?.querySelectorAll('input, select'))
        .filter(element => !element.closest('.schema-field__hidden') && !element.disabled)
      const currentIndex = fieldList.indexOf(document.activeElement)
      if (!fieldList.length || !document.activeElement || currentIndex < 0) return
      const previousElement = fieldList[currentIndex - 1]
      const nextElement = fieldList[currentIndex + 1]
      if (e.shiftKey && previousElement) previousElement.focus()
      if (!e.shiftKey && nextElement) nextElement.focus()
      if (!e.shiftKey && !nextElement) document.getElementById('submit-btn').click()
    },
    async setForm() { // < safe to remove when fully on new form
      this.suggestionFields = []
      this.formValues = {}
      await this.setObject({ object: this.item })
      await this.loadSchema()
      this.form = this.schema
      this.loadFormValuesAndSuggestionFields()
    },
    setJurisdiction() { // < safe to remove when fully on new form
      this.contextualJurisdiction = typeof(this.item.jurisdiction) === 'object' ?
        this.item.jurisdiction : this.findByName(this.item.jurisdiction)
    },
    loadFormValuesAndSuggestionFields() { // < safe to remove when fully on new form
      let formInfo = this.isMontanaVehiclesRegistration ? this.orderItemData : this.item.company.details
      const [formValues, suggestionFields] = fillFormFromDetails(
        this.form,
        formInfo,
      )

      if (!_.isEmpty(formValues) && this.prefillFormValuesWithCompanyDetails) {
        this.formValues = formValues
        this.form.values = this.formValues
      }
      this.removeHomeStateFromFormValues()

      if (Object.keys(suggestionFields).length) this.suggestionFields = suggestionFields
    },

    // Hack - we do not want to show home state in the ORA, but we want to show it everywhere else.
    removeHomeStateFromFormValues(){ // < safe to remove when fully on new form
      this.form = this.form.filter(e => e.name !== 'home_state')
    },
    async saveDetails() {
      this.loading = true

      if (this.usingNewForm) {
        const validationResult = await this.validateCurrentForm()
        await this.setShowValidationErrors(true)

        if (validationResult.isValid) {
          const result = await this.submitData()
          if (result.success) this.$emit('completed', this.item)

          if (result.message !== 'backend-group-errors') {
            this.displaySubmissionPopup(
              result.message,
              result.success ? 'Success' : 'Error',
              result.success ? 'success' : 'danger'
            )
          }
        } else {
          if (this.isEIN) {
            const m = this.$createElement
            const message =
              m('ul', validationResult.invalidTitles.map(title => m('li', `${title}`)))
            const title = m(
              'div',
              [
                m('strong', 'Unable to complete: '),
                m('small', validationResult.invalidTitles.length > 1
                  ? 'These fields are required'
                  : 'This field is required'),
              ]
            )

            this.displaySubmissionPopup([message], [title], 'danger')
          } else {
            this.displaySubmissionPopup(
              'Please fill out the rest of the required information.', 'Error', 'danger'
            )
          }
        }

        this.loading = false
        return
      }

      // \/ safe to remove when fully on new form
      const form = this.$refs[`filing-form-${this.item.id}`].form

      if (!form.validate()) {
        if (this.isEIN) {
          const invalidRequiredFields = form.children.filter(obj => !obj.isValid)
            .map(obj => obj.title)

          const m = this.$createElement
          const message = m('ul', invalidRequiredFields.map(invalidField => m('li', `${invalidField}`)))
          const title = m(
            'div',
            [
              m('strong', 'Unable to complete: '),
              m('small', invalidRequiredFields.length > 1
                ? 'These fields are required'
                : 'This field is required'),
            ]
          )

          this.$bvToast.toast([message], {
            title: [title],
            variant: 'danger',
            solid: true,
          })
        } else {
          this.$bvToast.toast('Please fill out the rest of the required information.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        }
      } else {
        const result = await saveDetails({
          form: this.form,
          formValues: this.formValues,
          item: this.item,
        })

        if (result[0].success) this.$emit('completed', this.item)

        this.$bvToast.toast(result[0].message, {
          title:  result[0].success ? 'Success' : 'Error',
          variant: result[0].success ? 'success' : 'danger',
          solid: true,
        })

        if (this.CREATE_DOCUMENT_FORMS.includes(this.item.name)) {
          await createDocumentFromForm(this.item, form)
        }
      }

      this.loading = false
      // /\ safe to remove when fully on new form
    },
    async saveFormProgress() {
      this.loading = true
      if (this.usingNewForm) {
        const result = await this.submitData(false)

        if (result.message !== 'backend-group-errors') {
          this.displaySubmissionPopup(
            result.message,
            result.success ? 'Save Successful' : 'Error',
            result.success ? 'success' : 'danger'
          )
        }
      }

      this.loading = false
    },
    displaySubmissionPopup(message, title, variant) {
      this.$bvToast.toast(message, { title, variant, solid: true })
    },
    suggestionToggled(event) { // < safe to remove when fully on new form
      if (event.field.meta && event.field.meta.type === 'address') {
        if (event.usingSuggestion === false) {
          delete this.suggestionFields[event.field.name]
        }
      }
    },
    async raSignUp() { // < safe to remove when fully on new form, this is handled by the new address component
      let raProduct = await this.fetchProduct({
        productKind: 'registered_agent_product',
        jurisdictionId: this.contextualJurisdiction.state_province_region === 'Federal' ? this.domesticRegistration.jurisdiction_id : this.contextualJurisdiction.id,
      })
      if (raProduct.length) {
        raProduct = raProduct[0]
        await this.addToCart( { ...raProduct, skipResolveOptionalItems: true } )
      }
    },
    showContactModal() { // < safe to remove when fully on new form
      this.$refs['contact-modal'].show()
    },
    showItemRequiringAttentionInformationModal() {
      this.$bvModal.show('item-requiring-attention-information-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
  .edit-link {
    color: #35ccc8 !important;
    text-decoration: underline !important;
    cursor: pointer;
  }

  .loading-div {
    min-height: 100px;
  }
  @media only screen and (max-width: 660px) {
    .custom-select {
      width: 100% !important;
    }
  }
</style>
